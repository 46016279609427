import React from "react";
import { Component } from "react";


class header extends Component {

    render() {
        return (
            <div>
                <nav className="navbar navbar-expand-md navbar-light bg-light sticky-top">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="#"><img src="https://elitebody.cl/img/logoTrasparente.png" /></a>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarResponsive">
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item active">
                                    <a className="nav-link" href="https://www.elitebody.cl">Inicio</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="https://www.elitebody.cl/#misionVision">Misión y Visión</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="https://www.elitebody.cl/tratamientos">Tratamientos</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="https://www.elitebody.cl/sucursales">Sucursales</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#">Contacto</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        )
    }
}

export default header;