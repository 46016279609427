import React from "react";
import { Component } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';



class socialmedia extends Component {

    componentDidMount() {
        AOS.init({ duration: 1000 });
    }
    render() {
        return (
            <div>
                <div className="container-fluid padding">
                    <div className="row text-center padding">
                        <div className="col-12">
                            <h2>Nuestras Redes</h2>
                        </div>
                        <div className="col-12 social padding">
                            <a href="https://m.facebook.com/profile.php?id=61552200814802&refid=13&__tn__=%2Cg" target="_blank"><i className="fab fa-facebook"></i></a>
                            <a href="https://www.instagram.com/elitebody.cl/" target="_blank"><i className="fab fa-instagram"></i></a>

                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default socialmedia;