import React from "react";
import { Component } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import axios from "axios";
import config from "../config";
import ReCAPTCHA from "react-google-recaptcha";



class form extends Component {


  constructor(props) {

    super(props);
    this.state = {
      nombre: '',
      apellido: '',
      email: '',
      telefono: '',
      tratamiento: 0,
      listaTratamientos: [],
      nombreError: '',
      apellidoError: '',
      emailError: '',
      telefonoError: '',
      tratamientoError: '',
      successMessage: '',
      recaptchaVerified: false,
      recaptchaError: '',
    };

    this.handleChangeNombre = this.handleChangeNombre.bind(this);
    this.handleChangeApellido = this.handleChangeApellido.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangeTelefono = this.handleChangeTelefono.bind(this);
    this.handleChangeTratamiento = this.handleChangeTratamiento.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }




  componentDidMount() {
    AOS.init({ duration: 1000 });


    axios.get("https://api20.s4p.cl:442/api/leads/listatratamientos")
      .then(res => {
        const data = res.data;
        const listaTratamientos = data.Tratamientos.map(t => (
          <option key={t.idTratamiento} value={t.idTratamiento}>
            {t.nombreTratamiento}
          </option>
        ));
        this.setState({ listaTratamientos })
        this.setState({
          Tratamientos: res.data.Tratamientos,
          status: 'OK'
        })
      })


  }

  handleChangeNombre(event) {
    this.setState({ nombre: event.target.value });
  }

  handleChangeApellido(event) {
    this.setState({ apellido: event.target.value });
  }

  handleChangeEmail(event) {
    this.setState({ email: event.target.value });
  }

  handleChangeTelefono(event) {
    this.setState({ telefono: event.target.value });
  }

  handleChangeTratamiento(event) {
    this.setState({ tratamiento: event.target.value });
  }

  handleRecaptchaChange = (value) => {
    this.setState({ recaptchaVerified: true });
  }
  handleSubmit(event) {

    event.preventDefault();

    let errores = false;

    if (this.state.nombre === '') {
      errores = true;
      this.setState({
        nombreError: 'Debe ingresar los nombre'
      });
    } else {
      this.setState({
        nombreError: ''
      });
    }

    if (this.state.apellido === '') {
      errores = true;
      this.setState({
        apellidoError: 'Debe ingresar los apellidos'
      });
    } else {
      this.setState({
        apellidoError: ''
      });
    }

    if (this.state.apellido === '') {
      errores = true;
      this.setState({
        apellidoError: 'Debe ingresar los apellidos'
      });
    } else {
      this.setState({
        apellidoError: ''
      });
    }

    if (this.state.email === '') {
      errores = true;
      this.setState({
        emailError: 'Debe ingresar correo'
      });
    } else {
      this.setState({
        emailError: ''
      });
    }

    if (this.state.telefono === '') {
      errores = true;
      this.setState({
        telefonoError: 'Debe ingresar teléfono'
      });
    } else {
      this.setState({
        telefonoError: ''
      });
    }

    if (this.state.tratamiento === 'Tratamientos' || this.state.tratamiento === '' || this.state.tratamiento === 0) {
      errores = true;
      this.setState({
        tratamientoError: 'Debe seleccionar un tratamiento'
      });
    } else {
      this.setState({
        tratamientoError: ''
      });
    }

    if (!this.state.recaptchaVerified) {
      errores = true;
      this.setState({
        recaptchaError: 'Campo requerido'
      });
    } else {
      this.setState({
        recaptchaError: ''
      });
    }


    if (errores) {
      return;
    }
    const data = {
      "Nombre": this.state.nombre,
      "Apellido": this.state.apellido,
      "Email": this.state.email,
      "Telefono": this.state.telefono,
      "Tratamiento": this.state.tratamiento
    }

    axios.post('https://api20.s4p.cl:442/api/Leads/Carga', data)
      .then(res => {
        this.setState({ successMessage: 'Datos enviados con éxito' });
        console.log(res.data);

        axios.get(`https://api20.s4p.cl:442/api/Leads/Correos?id=${this.state.tratamiento}&correo=${this.state.email}&nombre=${this.state.nombre}&key=${config.apiKey}`)
          .then(res => {
            console.log(res.data);
            console.log('Correo enviado correctamente');
            // Puedes manejar la respuesta de esta segunda API aquí
          })
          .catch(error => {
            console.error('Error al enviar correo:', error);
          });

      })
      .catch(error => {
        console.error(error);
      });
  }

  render() {
    return (
      <div className="form-background">
        <div className="row">
          <div className="col-md-6">
            <div className="background-image">
              <h1>Contactate con nosotros</h1>
              <p>Consulta por nuestros tratamientos.</p>
            </div>
          </div>
          <div className="col-md-6 d-flex align-items-center justify-content-center flex-column">
            <div className="form-container">
              <h4>¡Suscríbete para recibir novedades!</h4>
              <form id="msform" onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <label>Nombre:</label>
                  <input type="text" className={`form-control ${this.state.nombreError ? 'error' : ''}`} placeholder="Nombre" onChange={this.handleChangeNombre} />
                  {this.state.nombreError ? (
                    <span className="error-message">{this.state.nombreError}</span>
                  ) : null}
                </div>
                <div className="form-group">
                  <label>Apellido:</label>
                  <input type="text" className={`form-control ${this.state.apellidoError ? 'error' : ''}`} placeholder="Apellido" onChange={this.handleChangeApellido} />
                  {this.state.apellidoError ? (
                    <span className="error-message">{this.state.apellidoError}</span>
                  ) : null}
                </div>
                <div className="form-group">
                  <label>Correo:</label>
                  <input type="email" className={`form-control ${this.state.emailError ? 'error' : ''}`} placeholder="ejemplo@correo.cl" onChange={this.handleChangeEmail} />
                  {this.state.emailError ? (
                    <span className="error-message">{this.state.emailError}</span>
                  ) : null}
                </div>
                <div className="form-group">
                  <label>Teléfono:</label>
                  <input type="number" className={`form-control ${this.state.telefonoError ? 'error' : ''}`} placeholder="5698765432" onChange={this.handleChangeTelefono} />
                  {this.state.telefonoError ? (
                    <span className="error-message">{this.state.telefonoError}</span>
                  ) : null}
                </div>
                <div className="form-group">
                  <label>Selecciona una opción:</label>
                  <select className={`form-control ${this.state.tratamientoError ? 'error' : ''}`} name="tratamiento" value={this.state.tratamiento} placeholder="tratamiento" onChange={this.handleChangeTratamiento}>
                    <option value="tratamientos"> Tratamientos </option>
                    {this.state.listaTratamientos}
                  </select>
                  {this.state.tratamientoError ? (
                    <span className="error-message">{this.state.tratamientoError}</span>
                  ) : null}
                </div>
                <ReCAPTCHA
                  sitekey={config.sitekey}
                  onChange={this.handleRecaptchaChange}
                />
                {this.state.recaptchaError ? (
                    <span className="error-message">{this.state.recaptchaError}</span>
                  ) : null}
                <button type="submit" className="btn btn-primary">Enviar</button>
                {this.state.successMessage && <p>{this.state.successMessage}</p>}
                
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default form;