import React from "react";
import { Component } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';


class footer extends Component {

    componentDidMount() {
        AOS.init({ duration: 1000 });
      }
    
    render() {
        return (
            <div>
                <footer>
                    <div className="container-fluid padding" data-aos="fade-up"
                        data-aos-offset="200"
                        data-aos-delay="50"
                        data-aos-duration="1000">
                        <div className="row text-center">
                            <div className="col-md-4 pt-0">
                                <div className="footer-image">
                                    <img src="https://elitebody.cl/img/logoBlanco.png" /></div>
                                <hr className="light" />
                                <p>Ver sucursales</p>
                                <a href="https://www.elitebody.cl/sucursales.html" className="btn btn-outline-secondary">Ir</a>
                            </div>
                            <div className="col-md-4">
                                <hr className="light" />
                                <h5>Horarios</h5>
                                <hr className="light" />
                                <p>Lunes a Viernes: 10am - 20pm</p>
                                <p>Sabado y Domingo: Cerrado</p>
                            </div>

                            <div className="col-12">
                                <hr className="light-100" />
                                <h5>&copy; EliteBody.cl </h5>
                            </div>
                        </div>
                    </div>
                </footer>


            </div>
        )
    }
}

export default footer;